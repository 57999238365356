// export const URL = "http://170.0.27.248:5000/"
// export const URL = "http://localhost:8000/"
// export const THIS_URL = "http://localhost:3000/"
// export const NAME = "localhost"
export const LOGO = ""
export const DEBUG = false;

// export const URL = "https://verdazback.enterscience.com.br/"
// export const THIS_URL = "https://verdaz.enterscience.com.br/"
// export const NAME = "verdaz"

// export const URL = "https://testeback.enterscience.com.br/"
// export const THIS_URL = "https://teste.enterscience.com.br/"
// export const NAME = "teste"

// export const URL = "https://blueensapi.verdaz.com.br/"
// export const THIS_URL = "https://blueen.verdaz.com.br/"
// export const NAME = "blueen"

// export const URL = "https://brkapi.verdaz.com.br/"
// export const THIS_URL = "https://brk.verdaz.com.br/"
// export const NAME = "brk"

// export const URL = "https://ecoracaoapi.verdaz.com.br/"
// export const THIS_URL = "https://ecoracao.verdaz.com.br/"
// export const NAME = "ecoracao"


// export const URL = "https://iptapi.verdaz.com.br/"
// export const THIS_URL = "https://ipt.verdaz.com.br/"
// export const NAME = "ipt"

// export const URL = "https://provinciaapi.verdaz.com.br/"
// export const THIS_URL = "https://provincia.verdaz.com.br/"
// export const NAME = "provincia"

// export const URL = "https://engemasaapi.verdaz.com.br/"
// export const THIS_URL = "https://engemasa.verdaz.com.br/"
// export const NAME = "engemasa"

// export const URL = "https://cooperlagosapi.verdaz.com.br/"
// export const THIS_URL = "https://cooperlagos.verdaz.com.br/"
// export const NAME = "cooperlagos"

export const URL = "https://americanflexapi.blueen.app/"
export const THIS_URL = "https://americanflex.blueen.app/"
export const NAME = "americanflex"

// export const URL = "https://braileapi.blueen.app/"
// export const THIS_URL = "https://braile.blueen.app/"
// export const NAME = "braile"

// export const URL = "https://pefapi.blueen.app/"
// export const THIS_URL = "https://pef.blueen.app/"
// export const NAME = "pef"

// export const URL = "https://temiapi.blueen.app/"
// export const THIS_URL = "https://temi.blueen.app/"
// export const NAME = "temi"

// export const URL = "https://rptsapi.blueen.app/"
// export const THIS_URL = "https://rpts.blueen.app/"
// export const NAME = "rpts"

// export const URLopen = "http://127.0.0.1:8001/"
export const URLopen = "https://api.blueen.app/"

// export const URLadmin = "http://127.0.0.1:8002/"
export const URLadmin = "https://adminapi.blueen.app/"


